import Accordion from 'react-bootstrap/Accordion';
import Translate from '../../AppContext/Translate';
import ListGroup from 'react-bootstrap/ListGroup';
import UnitNumber from "../../Components/UnitNumber";

function CourseIndex({units, selUnit, setSelUnit}) {
    const unitNumber = selUnit && 'number' in selUnit ? selUnit.number : -1;
    return units ? <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
            <Accordion.Header><Translate>Виберіть тему</Translate></Accordion.Header>
            <Accordion.Body>
                <ListGroup>
                {units.map((unit, i) => {
                    return <ListGroup.Item key={i} action variant={unitNumber===unit.number ? "secondary": "light"}
                        onClick={() => { setSelUnit(unit); }}>
                        <UnitNumber number={unit.number}/>{unit.title}
                    </ListGroup.Item>
                })}
                </ListGroup>
            </Accordion.Body>
        </Accordion.Item>
    </Accordion> : null
}

export default CourseIndex
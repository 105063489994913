import { useState, useContext, useRef, lazy, Suspense } from "react";
import Button from 'react-bootstrap/Button';
import { BsTypeBold, BsTypeItalic, BsTypeUnderline, BsListUl, BsLink, BsImage, BsCameraVideo, BsFile, BsEmojiSmile } from 'react-icons/bs';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { AppContext } from "../AppContext/AppContext";
import Translate, { translate, translateWithNumber } from "../AppContext/Translate";
import Form from 'react-bootstrap/Form';
import validator from 'validator'
import imageCompression from 'browser-image-compression';
import { MdRemoveCircle } from 'react-icons/md';
import ShakeOnError from "./ShakeOnError";
import { AiOutlineNotification } from 'react-icons/ai';
import AttFile from "./AttFile";
import BeatLoader from "react-spinners/BeatLoader";
import ContentEditor from "./ContentEditor";
import { insertEmoji, insertLink } from "./htmlInsert";

const EmojiPicker = lazy(() => import('emoji-picker-react'));

function RTEditor({ item, onChange, bkgColor = "white", multipleFiles = true, maxVideoSize = 450,
    allowImages = true, allowVideos = true, allowFiles = true, flagChanged = null }) {
    var { dicLang } = useContext(AppContext);
    const [error, setError] = useState(null);
    const [link, setLink] = useState("");
    const [cursorPosition, setCursorPosition] = useState(null);

    const editor = useRef(null);
    const mr = { marginRight: "2px" };
    const mrb = { marginRight: "8px" };
    const Loading = () => <BeatLoader size={24} color="#0d6efd" />;

    const updateText = (newText) => {
        onChange({ text: newText, files: item.files });
    }

    function onClickEmoji(emojiData) {
        if (error) setError(null);
        if (cursorPosition) {
            document.body.click();
            editor.current.focus();
            const newText = insertEmoji('<img alt="" width="18px" height="18px" src="' +
                emojiData.getImageUrl("apple") + '"/>', item.text, cursorPosition.cursorPosition);
            updateText(newText);
        }
    }

    const emojiPicker = (
        <Popover id="popover-emoji" style={{ maxWidth: "100%" }}>
            <Popover.Body style={{ padding: "0px" }}>
                <Suspense fallback={<Loading />}>
                    <EmojiPicker onEmojiClick={onClickEmoji} height={360} autoFocusSearch={false}
                        previewConfig={{
                            defaultCaption: translate("Виберіть смайлик!", dicLang),
                            defaultEmoji: "1f60a"
                        }}
                        categories={[
                            { name: translate("Рекомендовані", dicLang), category: 'suggested' },
                            { name: translate("Люди", dicLang), category: 'smileys_people' },
                            { name: translate("Природа", dicLang), category: 'animals_nature' },
                            { name: translate("Харчування", dicLang), category: 'food_drink' },
                            { name: translate("Поїздки", dicLang), category: 'travel_places' },
                            { name: translate("Діяльність", dicLang), category: 'activities' },
                            { name: translate("Об'єкти", dicLang), category: 'objects' },
                            { name: translate("Символи", dicLang), category: 'symbols' },
                            { name: translate("Прапори", dicLang), category: 'flags' }]}
                    />
                </Suspense>
            </Popover.Body>
        </Popover>
    );

    const linkPopover = (
        <Popover id="popover-link" onSubmit={(evnt) => {
            evnt.preventDefault();
            document.body.click();
            editor.current.focus();
            if (validator.isURL(link) && cursorPosition) {
                const newText = insertLink(link, item.text, cursorPosition.selectionStart,
                    cursorPosition.selectionEnd);
                updateText(newText);
            }
        }}>
            <Form style={{ padding: "16px" }}>
                <Form.Group className="mb-3" controlId="formLink">
                    <Form.Label><Translate>Скопіюйте посилання сюди</Translate></Form.Label>
                    <Form.Control value={link} type="text" onChange={evnt => { setLink(evnt.target.value); }} />
                </Form.Group>
                <Button variant="primary" type="submit"><Translate>Прийняти</Translate></Button>
            </Form>
        </Popover>
    );

    const execCommand = (evt, cmd) => {
        evt.preventDefault();
        document.execCommand(cmd, false, null);
    }

    const addList = (evt) => {
        evt.preventDefault();
        const sel = window.getSelection();
        if (sel && sel.rangeCount > 0 && sel.toString().trim() !== "") {
            const range = sel.getRangeAt(0);
            let isInList = false;
            let currentNode = range.startContainer;
            while (currentNode && currentNode !== editor.current) {
                if (currentNode.nodeName === "UL") {
                    isInList = true;
                    break;
                }
                currentNode = currentNode.parentNode;
            }
            if (isInList) {
                const ulElement = currentNode;
                let plainText = "";
                ulElement.querySelectorAll("li").forEach(li => {
                    plainText += li.textContent + "\n";
                });
                const textNode = document.createTextNode(plainText.trim() + "\n");
                ulElement.parentNode.replaceChild(textNode, ulElement);
            } else {
                const selectedText = sel.toString();
                const lines = selectedText.split(/\r?\n/).filter(line => line.trim() !== "");
                const ul = document.createElement("ul");
                lines.forEach(line => {
                    const li = document.createElement("li");
                    li.textContent = line.trim();
                    ul.appendChild(li);
                });
                range.deleteContents();
                range.insertNode(ul);
            }
            updateText(editor.current.innerHTML);
            sel.removeAllRanges();
        } else {
            alert(translate("Спочатку виберіть текст, який потрібно перетворити на список", dicLang));
        }
    }

    async function imageChange(evnt) {
        document.body.click();
        if (error) setError(null);
        const imageFile = evnt.target.files[0];
        if (imageFile.name.toLowerCase() === 'cover.jpg') {
            setError(translate("Помилка", dicLang) + ": " + translate("'cover.jpg' - це зарезервована назва, виберіть іншу назву для свого зображення", dicLang));
        } else {
            const options = { initialQuality: 0.85, maxSizeMB: 1, maxWidthOrHeight: 800, useWebWorker: true };
            try {
                const compressedFile = await imageCompression(imageFile, options);
                var reader = new FileReader();
                reader.readAsDataURL(compressedFile);
                reader.onloadend = function (e) {
                    onChange({
                        text: item.text,
                        files: item.files.concat([new AttFile('i', imageFile.name,
                            { size: imageFile.size, buffer: reader.result })])
                    });
                };
            } catch (error) {
                setError(translate("Помилка", dicLang) + ": " + translate("вибраний файл не є припустимим зображенням", dicLang));
            }
        }
    }

    const imagePopover = (
        <Popover id="popover-image">
            <form style={{ padding: "16px" }}>
                <input id="ifile" type="file" accept=".jpg,.jpeg" multiple={false} onChange={imageChange} />
            </form>
        </Popover>
    );

    function fileChange(evnt) {
        document.body.click();
        const file = evnt.target.files[0];
        const size = file.size / 1048576;
        if (error) setError(null);
        if (size > 10) {
            setError(translate("Помилка", dicLang) + ": " + translate("файли розміром понад 10 Мб не можна завантажувати", dicLang));
        } else {
            const fileReader = new FileReader();
            fileReader.onload = (e) => {
                const { result } = e.target;
                if (result) {
                    onChange({
                        text: item.text,
                        files: item.files.concat([new AttFile('f', file.name, { size: size, buffer: result })])
                    });
                } else {
                    setError(translate("Помилка", dicLang) + ": " + translate("не вдалося завантажити файл", dicLang));
                }
            }
            fileReader.readAsDataURL(file);
        }
    }

    function videoChange(evnt) {
        document.body.click();
        const file = evnt.target.files[0];
        const size = file.size / 1048576;
        if (error) setError(null);
        const ext = file.name.split('.').pop().toLowerCase();
        if (ext !== 'mp4') {
            setError(translate("Помилка", dicLang) + ": " + translate("не вдалося завантажити файл", dicLang));
        } else if (size > maxVideoSize) {
            setError(translate("Помилка", dicLang) + ": " +
                translateWithNumber("завеликий розмір відео (максимум * Мб)", maxVideoSize, dicLang));
        } else {
            const fileReader = new FileReader();
            fileReader.onload = (e) => {
                const { result } = e.target;
                if (result) {
                    onChange({
                        text: item.text,
                        files: item.files.concat([new AttFile('v', file.name, { size: size, buffer: result })])
                    });
                } else {
                    setError(translate("Помилка", dicLang) + ": " + translate("не вдалося завантажити файл", dicLang));
                }
            }
            fileReader.readAsDataURL(file);
        }
    }

    const filePopover = (
        <Popover id="popover-file">
            <form style={{ padding: "16px" }}>
                <input type="file" accept=".avi,.doc,.docx,.jpg,.mp3,.mp4,.pdf,.ppt,.pptx,.txt,.xls,.xlsx,.zip"
                    multiple={false} onChange={fileChange} />
            </form>
        </Popover>
    );

    const videoPopover = (
        <Popover id="popover-video">
            <form style={{ padding: "16px" }}>
                <input type="file" accept=".mp4" multiple={false} onChange={videoChange} />
            </form>
        </Popover>
    );

    const deleteFile = (evnt, data) => {
        evnt.preventDefault();
        var files = [];
        for (var i = 0; i < item.files.length; i++) {
            if (item.files[i] === data) {
                if (!data.markAsDeleted())
                    files.push(data);
            } else files.push(item.files[i]);
        }
        onChange({ text: item.text, files: files });
    }

    const containVideo = (item) => {
        if (item && item.files) {
            for (var i = 0; i < item.files.length; i++) {
                if (item.files[i].isVideo() && !item.files[i].deleted)
                    return true;
            }
        }
        return false;
    }

    const disabled = !multipleFiles && item.files.length > 0;

    return <div>
        <ShakeOnError error={error !== null}>
            {error !== null &&
                <div style={{ backgroundColor: "#ffcfcf", padding: "4px", display: "flex" }}>
                    <AiOutlineNotification size={28} />
                    <p style={{ margin: "0px 0px 0px 8px", fontWeight: "600" }}>{error}.</p>
                </div>
            }
            <div style={{ backgroundColor: bkgColor, padding: "2px", border: "1px solid #dee2e6" }}>
                <Button variant="outline-dark" size="sm" style={mr} onMouseDown={evt => { execCommand(evt, "bold"); }}><BsTypeBold /></Button>
                <Button variant="outline-dark" size="sm" style={mr} onMouseDown={evt => { execCommand(evt, "italic"); }}><BsTypeItalic /></Button>
                <Button variant="outline-dark" size="sm" style={mrb} onMouseDown={evt => { execCommand(evt, "underline"); }}><BsTypeUnderline /></Button>
                <Button variant="outline-dark" size="sm" style={mr} onMouseDown={evt => { addList(evt); }}><BsListUl /></Button>
                <OverlayTrigger rootClose trigger="click" placement="bottom" overlay={linkPopover}>
                    <Button variant="outline-dark" size="sm" style={mrb}><BsLink /></Button>
                </OverlayTrigger>
                {allowImages &&
                    <OverlayTrigger rootClose trigger="click" placement="bottom" overlay={imagePopover}>
                        <Button variant="outline-dark" size="sm" style={mr} disabled={disabled}><BsImage /></Button>
                    </OverlayTrigger>}
                {allowVideos &&
                    (containVideo(item) ? <Button variant="outline-dark" size="sm" style={mr} disabled><BsCameraVideo /></Button> :
                        <OverlayTrigger rootClose trigger="click" placement="bottom" overlay={videoPopover}>
                            <Button variant="outline-dark" size="sm" style={mr} disabled={disabled}><BsCameraVideo /></Button>
                        </OverlayTrigger>)
                }
                {allowFiles &&
                    <OverlayTrigger rootClose trigger="click" placement="bottom" overlay={filePopover}>
                        <Button variant="outline-dark" size="sm" style={mr} disabled={disabled}><BsFile /></Button>
                    </OverlayTrigger>}
                <OverlayTrigger rootClose trigger="click" placement="bottom" overlay={emojiPicker}>
                    <Button variant="outline-dark" size="sm"><BsEmojiSmile /></Button>
                </OverlayTrigger>
            </div>
            <ContentEditor text={item.text} onTextChange={updateText} editor={editor}
                setCursorPosition={setCursorPosition} flagChanged={flagChanged} />
            {item.files.length > 0 &&
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {item.files.map((data, i) => {
                        return data.deleted ? <div key={i} /> :
                            <div key={i} style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                                {data.getPreview()}
                                <button className='ChatToolButton' onClick={(evnt) => deleteFile(evnt, data)}>
                                    <MdRemoveCircle color="maroon" size={20} />
                                </button>
                            </div>
                    })
                    }
                </div>}
        </ShakeOnError>
    </div>
}

export default RTEditor
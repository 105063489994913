import { lazy, Suspense, useState } from "react";
import Translate, { translate } from "../AppContext/Translate";
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import CitySearch from "./Novaposhta/CitySearch";
import BranchSearch from "./Novaposhta/BranchSearch";
import StreetSearch from "./Novaposhta/StreetSearch";

const PhoneInput = lazy(() => import('react-phone-number-input'));

function CustomToggle({ eventKey, selectedMethod, handleChange }) {
    const decoratedOnClick = useAccordionButton(eventKey);

    const handleSelection = () => {
        handleChange(eventKey);
        decoratedOnClick();
    };

    return (
        <Form.Check
            type="radio"
            name="accordion"
            checked={selectedMethod === eventKey}
            onChange={handleSelection}
            style={{ marginRight: '10px' }}
        />
    );
}

function DeliveryAddress({ userName, dicLang, error, updateAddress }) {
    const [clientName, updateClientName] = useState(userName ? userName.trim().split(' ')[0] : '');
    const [surname, updateSurname] = useState(() => {
        if (!userName || !userName.trim()) return '';
        const nameParts = userName.trim().split(' ');
        return nameParts.length > 1 ? nameParts[nameParts.length - 1] : '';
    });
    const [familyName, updateFamilyName] = useState('');
    const [phone, updatePhone] = useState('');
    const [selectedMethod, updateSelectedMethod] = useState("0");
    const [city, updateCity] = useState(null);
    const [yard, updateYard] = useState("");
    const [door, updateDoor] = useState("");
    const [cp, updateCp] = useState("");

    const setPhone = (phone) => {
        updatePhone(phone);
        updateAddress('phone', phone);
    }

    const setClientName = (name) => {
        updateClientName(name);
        updateAddress('name', name.trim());
    }

    const setSurname = (surname) => {
        updateSurname(surname);
        updateAddress('surname', surname.trim());
    }

    const setFamilyName = (name) => {
        updateFamilyName(name);
        updateAddress('familyName', name.trim());
    }

    const setSelectedMethod = (method) => {
        if (method !== selectedMethod) {
            updateSelectedMethod(method);
            updateAddress('method', method);
            updateAddress('city', null);
            updateAddress('branch', null);
            updateAddress('street', null);
            updateAddress('yard', '');
            updateAddress('door', '');
            updateAddress('cp', '');
        }
    }

    const setCity = (c) => {
        updateCity(c);
        updateAddress('city', c);
    }

    const setBranch = (b) => {
        updateAddress('branch', b);
    }

    const setStreet = (s) => {
        updateAddress('street', s);
    }

    const setYard = (y) => {
        updateYard(y);
        updateAddress('yard', y.trim());
    }

    const setDoor = (d) => {
        updateDoor(d);
        updateAddress('door', d.trim());
    }

    const setCp = (c) => {
        updateCp(c);
        updateAddress('cp', c.trim());
    }

    const handleChange = (eventKey) => {
        setSelectedMethod(eventKey);
    };

    return <div style={{
        border: "gray thin solid", borderRadius: "12px", padding: "10px",
        width: "600px", maxWidth: "100%", margin: "0px auto 24px auto"
    }}>
        <h4><Translate>Адреса доставки</Translate></h4>
        <hr />
        <div>
            <div style={{ display: "flex", alignItems: "baseline" }}>
                <span style={{ marginRight: "56px" }}><Translate>Ім'я</Translate></span>
                <input type="text" value={clientName} className="form-control" placeholder=""
                    onChange={(e) => setClientName(e.target.value)} style={{
                        maxWidth: "290px",
                        borderRadius: "0px", borderColor: "rgb(118, 118, 118)"
                    }} />
            </div>
            {error === "name" && <p style={{color: "red", textAlign: "left", marginBottom: "8px"}}><Translate>Помилка</Translate>, <Translate>неправильне ім'я</Translate></p>}
            <div style={{ display: "flex", alignItems: "baseline" }}>
                <span style={{ marginRight: "20px" }}><Translate>Прізвище</Translate></span>
                <input type="text" value={surname} className="form-control" placeholder=""
                    onChange={(e) => setSurname(e.target.value)} style={{
                        maxWidth: "290px",
                        borderRadius: "0px", borderColor: "rgb(118, 118, 118)"
                    }} />
            </div>
            {error === "surname" && <p style={{color: "red", textAlign: "left", marginBottom: "8px"}}><Translate>Помилка</Translate>, <Translate>неправильне прізвище</Translate></p>}
            <div style={{ display: "flex", alignItems: "baseline" }}>
                <span style={{ marginRight: "9px" }}><Translate>По батькові</Translate></span>
                <input type="text" value={familyName} className="form-control" placeholder=""
                    onChange={(e) => setFamilyName(e.target.value)} style={{
                        maxWidth: "290px",
                        borderRadius: "0px", borderColor: "rgb(118, 118, 118)"
                    }} />
            </div>
            <div style={{ maxWidth: "367px", marginBottom: "18px" }}>
                <Suspense>
                    <PhoneInput placeholder={translate("Номер телефону", dicLang)} value={phone}
                        onChange={setPhone} />
                </Suspense>
                {error === "phone" && <p style={{color: "red", textAlign: "left"}}><Translate>Помилка</Translate>, <Translate>недійсний номер</Translate></p>}
            </div>
            <Accordion activeKey={selectedMethod}>
                <Card>
                    <Card.Header style={{ display: 'flex', alignItems: 'center' }}>
                        <CustomToggle
                            eventKey="0"
                            selectedMethod={selectedMethod}
                            handleChange={handleChange}
                        />
                        <div onClick={() => handleChange("0")} style={{ cursor: 'pointer' }}>
                            <img alt="Нова пошта" src="/icons/logo_delivery_newpost.svg" width="28px" style={{margin: "0px 8px"}}/>
                            <span style={{fontWeight: "bold"}}>Нова пошта</span><span> (Відділення)</span>
                        </div>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <CitySearch setCity={setCity}/>
                            {error === "city" && <p style={{color: "red", textAlign: "left", marginBottom: "8px"}}><Translate>Помилка</Translate>, <Translate>місто не вказано</Translate></p>}
                            <BranchSearch city={city} setBranch={setBranch}/>
                            {error === "branch" && <p style={{color: "red", textAlign: "left", marginBottom: "8px"}}><Translate>Помилка</Translate>, <Translate>відділення не вказано</Translate></p>}
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Card.Header style={{ display: 'flex', alignItems: 'center' }}>
                        <CustomToggle
                            eventKey="1"
                            selectedMethod={selectedMethod}
                            handleChange={handleChange}
                        />
                        <div onClick={() => handleChange("1")} style={{ cursor: 'pointer' }}>
                            <img alt="Нова пошта" src="/icons/logo_delivery_newpost.svg" width="28px" style={{margin: "0px 8px"}}/>
                            <span style={{fontWeight: "bold"}}>Нова пошта</span><span> (Поштомат)</span>
                        </div>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                        <Card.Body>
                            <CitySearch setCity={setCity}/>
                            {error === "city" && <p style={{color: "red", textAlign: "left", marginBottom: "8px"}}><Translate>Помилка</Translate>, <Translate>місто не вказано</Translate></p>}
                            <BranchSearch city={city} setBranch={setBranch} postomat={true}/>
                            {error === "branch" && <p style={{color: "red", textAlign: "left", marginBottom: "8px"}}><Translate>Помилка</Translate>, <Translate>відділення не вказано</Translate></p>}
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Card.Header style={{ display: 'flex', alignItems: 'center' }}>
                        <CustomToggle
                            eventKey="2"
                            selectedMethod={selectedMethod}
                            handleChange={handleChange}
                        />
                        <div onClick={() => handleChange("2")} style={{ cursor: 'pointer' }}>
                            <img alt="Нова пошта" src="/icons/logo_delivery_newpost.svg" width="28px" style={{margin: "0px 8px"}}/>
                            <span style={{fontWeight: "bold"}}>Нова пошта</span><span> (Курʼєрська доставка до дверей)</span>
                        </div>
                    </Card.Header>
                    <Accordion.Collapse eventKey="2">
                        <Card.Body>
                            <CitySearch setCity={setCity}/>
                            {error === "city" && <p style={{color: "red", textAlign: "left", marginBottom: "8px"}}><Translate>Помилка</Translate>, <Translate>місто не вказано</Translate></p>}
                            <StreetSearch city={city} setStreet={setStreet}/>
                            {error === "street" && <p style={{color: "red", textAlign: "left", marginBottom: "8px"}}><Translate>Помилка</Translate>, <Translate>вулиця не вказана</Translate></p>}
                            <div style={{ display: "flex", alignItems: "baseline", width: "100%", marginTop: "8px" }}>
                                <span style={{ marginRight: "38px" }}><Translate>Введіть дім</Translate></span>
                                <input type="text" value={yard} className="form-control" placeholder="" maxLength="16"
                                    onChange={(e) => setYard(e.target.value)} style={{
                                    maxWidth: "100px", borderRadius: "5px", border: "1px solid rgb(204, 204, 204)"}} />
                            </div>
                            {error === "yard" && <p style={{color: "red", textAlign: "left", marginBottom: "8px"}}><Translate>Помилка</Translate>, <Translate>дім не вказано</Translate></p>}
                            <div style={{ display: "flex", alignItems: "baseline", width: "100%" }}>
                                <span style={{ marginRight: "48px" }}><Translate>Квартира</Translate></span>
                                <input type="text" value={door} className="form-control" placeholder="" maxLength="16"
                                    onChange={(e) => setDoor(e.target.value)} style={{
                                    maxWidth: "100px", borderRadius: "5px", border: "1px solid rgb(204, 204, 204)"}} />
                            </div>
                            {error === "door" && <p style={{color: "red", textAlign: "left", marginBottom: "8px"}}><Translate>Помилка</Translate>, <Translate>kвартира не вказано</Translate></p>}
                            <div style={{ display: "flex", alignItems: "baseline", width: "100%", marginBottom: "-8px" }}>
                                <span style={{ marginRight: "65px" }}><Translate>Індекс</Translate></span>
                                <input type="text" value={cp} className="form-control" placeholder="" maxLength="16"
                                    onChange={(e) => setCp(e.target.value)} style={{
                                    maxWidth: "100px", borderRadius: "5px", border: "1px solid rgb(204, 204, 204)"}} />
                            </div>
                            {error === "cp" && <p style={{color: "red", textAlign: "left", marginBottom: "8px"}}><Translate>Помилка</Translate>, <Translate>індекс не вказано</Translate></p>}
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </div>
    </div>
}

export default DeliveryAddress;
import { useEffect, useState } from "react";
import Translate from "../AppContext/Translate"
import { getAPICall } from "../Components/APICall";
import BeatLoader from "react-spinners/BeatLoader";
import CourseCard from "../Lists/CourseCard";
import MagButton from "../Components/MagButton";
import CourseList from "../Lists/CourseList";
import HorizontalScroll from "../Components/HorizontalScroll";

function SectionCourses({coursesRef}) {
    const [topCourses, setTopCourses] = useState([]);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        getAPICall('courses/top', {limit: 5}, (data) => {
            if (data) setTopCourses(data);
        });
    }, []);

    return <div style={{backgroundColor: "white", paddingTop: "48px", paddingBottom: "24px"}}>
        <img alt="" src="/bkg/decor/dec4.png" style={{position: "absolute", width: "4%", maxWidth: "32px",
            minWidth: "16px", top: "54px", left: "min(25%, 250px)", filter: "hue-rotate(274deg)"}}/>
        <div className="centeredSection" style={{textAlign: "right"}}>
            <h1><Translate>ТОП-KУРСИ</Translate></h1>
            <p className="fkenyan xlarge"><Translate>найбільш популярні курси<br/>серед наших студентів</Translate></p>
        </div>
        <div style={{padding: "28px", backgroundColor: "white"}} ref={coursesRef}>
            {topCourses.length === 0 ? <BeatLoader/> : 
            <HorizontalScroll itemSize={272}>
                {topCourses.map((course, i) => {
                    return <CourseCard key={i} course={course} top={true}/>
                })}
            </HorizontalScroll>}
            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
              <img alt="" src="/bkg/decor/dec8.png" width="32"/>
              <MagButton text="дивитися всі курси" icon={"/bkg/" + (open ? "up.png" : "down.png")}
                clickEff={{customFnc: () => { setOpen(!open); }}}/>
            </div>
            {open && <CourseList ignore={topCourses}/>}
        </div>
    </div>
}

export default SectionCourses
import { useContext, useState, useEffect } from "react";
import { AppContext } from "../AppContext/AppContext";
import Translate, { translate } from "../AppContext/Translate";
import { getAPICall, postAPICall } from "../Components/APICall";
import validator from 'validator'
import CartTotals, { calculateTotalOrder } from "./CartTotals";
import Menu from "../Menu/Menu";
import EmptyCart from "./EmptyCart";
import Table from 'react-bootstrap/Table';
import CartItem from "./CartItem";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Dropdown from 'react-bootstrap/Dropdown';
import Footer from "../Components/Footer";
import Form from 'react-bootstrap/Form';
import Button from "react-bootstrap/Button";
import WriteHelper from "../Components/WriteHelper";
import 'react-phone-number-input/style.css';
import DeliveryAddress from "./DeliveryAddress";

function Cart() {
  const { userId, token, cart, dicLang, userName } = useContext(AppContext);
  const [promos, setPromos] = useState([]);
  const [selectedPromo, setSelectedPromo] = useState(null);
  const [prices, setPrices] = useState(() => {
    var p = [];
    for (var i = 0; i < cart.length; i++) p.push(0);
    return p;
  });
  //const [purchaseData, setPurchaseData] = useState(null);
  const [address, setAddress] = useState(() => {
    const name = userName ? userName.trim().split(' ')[0] : '';
    let surname;
    if (!userName || !userName.trim()) surname = '';
    else {
      const nameParts = userName.trim().split(' ');
      surname = nameParts.length > 1 ? nameParts[nameParts.length - 1] : '';
    }
    return {
      phone: '', name: name, surname: surname, familyName: '', method: '0', city: null,
      branch: null, street: null, yard: '', door: '', cp: ''
    }
  });
  const [comment, setComment] = useState('');
  const [error, setError] = useState(null);
  //const formRef = useRef(null);

  var subtotal = 0;
  for (var i = 0; i < cart.length; i++) {
    subtotal += cart[i].amount * prices[i];
  }

  useEffect(() => {
    const getPromosSuccess = (data) => {
      if (data && data.length > 0)
        setPromos(data)
    }
    if (userId >= 0) {
      getAPICall('user/promos', { userId: userId, token: encodeURIComponent(token) }, getPromosSuccess);
    }
  }, [userId, token]);

  /*
  useEffect(() => {
    if (formRef && purchaseData) {
      formRef.current.submit();
    }
  }, [formRef, purchaseData]);
  */

  const getPromoDescription = (promo) => {
    var desc = translate('Знижка', dicLang) + ": " + promo.discount;
    if (promo.kind === 0) desc += ' грн';
    else desc += ' %'
    return desc;
  }

  const getPromosList = () => {
    var plist = [];
    if (promos && promos.length > 0) {
      plist.push({ id: -1, name: '---' });
      promos.forEach(function (promo) {
        plist.push({ id: promo.id, name: getPromoDescription(promo) });
      });
    }
    else plist.push({ id: -1, name: translate('У вас наразі немає промо-акцій', dicLang) });
    return plist;
  }

  const onPromoChanged = (value) => {
    if (value.id < 0) setSelectedPromo(null);
    else {
      promos.forEach(function (promo) {
        if (promo.id === value.id)
          setSelectedPromo(promo);
      });
    }
  }

  const updateSubtotal = (index, price) => {
    if (index >= 0 && index < cart.length) {
      setPrices(prevState => {
        let newState = [...prevState]
        newState[index] = price;
        return newState.slice(0, cart.length);
      }
      );
    }
  }

  const lineToStr = (line, price) => {
    if (line.kind === 'item') {
      return 'item,' + line.itemId + ',' + line.amount + ',' + price;
    } else {
      return 'course,' + line.courseId + ',' + line.groupId + ',' + price + ',' + line.packageId;
    }
  }

  const updateAddress = (field, value) => {
    const newAdd = { ...address, [field]: value };
    setAddress(newAdd);
  }

  const updateError = (e) => {
    alert(translate("У формі є помилки, виправте їх перед покупкою", dicLang));
    setError(e);
  }

  const createPayForm = (data, phone) => {
    const form = document.createElement('form');
    form.method = 'post';
    form.action = 'https://secure.wayforpay.com/pay';
    form.acceptCharset = 'utf-8';
    let inputs = [
      { name: 'apiVersion', value: '2' },
      { name: 'merchantAccount', value: data.merchant },
      { name: 'merchantAuthType', value: 'SimpleSignature' },
      { name: 'merchantDomainName', value: data.domain },
      { name: 'orderReference', value: data.orderId.toString() },
      { name: 'orderDate', value: data.orderDate },
      { name: 'amount', value: data.total.toString() },
      { name: 'productPrice[]', value: data.total.toString() },
      { name: 'currency', value: 'UAH' },
      { name: 'orderTimeout', value: '49000' },
      { name: 'productName[]', value: data.orderName },
      { name: 'productCount[]', value: '1' },
      { name: 'defaultPaymentSystem', value: 'card' },
      { name: 'merchantSignature', value: data.hash },
      { name: 'serviceUrl', value: data.service },
      { name: 'returnUrl', value: data.afterPayment },
      { name: 'clientEmail', value: data.email },
    ];
    if (phone !== '') inputs.push({ name: 'clientPhone', value: phone });
    inputs.forEach((inputData) => {
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = inputData.name;
      input.value = inputData.value;
      form.appendChild(input);
    });
    document.body.appendChild(form);
    form.submit();
  }

  const purchase = () => {
    const total = calculateTotalOrder(subtotal, selectedPromo);
    const promo = selectedPromo ? selectedPromo.id : -1;
    var lines = '';
    for (var i = 0; i < cart.length; i++) {
      if (lines.length > 0) lines += '|';
      lines += lineToStr(cart[i], prices[i]);
    }
    if (physicalProducts) {
      if (address.name.length < 1) {
        updateError('name');
        return;
      }
      if (address.name.length < 1) {
        updateError('name');
        return;
      }
      if (address.surname.length < 1) {
        updateError('surname');
        return;
      }
      if (!validator.isMobilePhone(address.phone)) {
        updateError('phone');
        return;
      }
      if (!address.city) {
        updateError('city');
        return;
      }
      if (address.method !== "2" && !address.branch) {
        updateError('branch');
        return;
      }
      if (address.method === "2") {
        if (!address.street) {
          updateError('street');
          return;
        }
        if (address.yard.length < 1) {
          updateError('yard');
          return;
        }
        if (address.door.length < 1) {
          updateError('door');
          return;
        }
        if (!validator.isPostalCode(address.cp, 'UA')) {
          updateError('cp');
          return;
        }
      }
      setError(null);
    }
    let fullName = [address.name, address.familyName, address.surname].filter(Boolean).join(" ");
    let addr = '';
    if (physicalProducts) {
      if (address.method === "2") {
        addr = address.cp + " Україна, ";
        if ('areaDescription' in address.city) addr += address.city.areaDescription + ", ";
        addr += address.city.description + ", " + address.street.streetsType + " " + address.street.description + ", " +
          address.yard + ", " + address.door;
      } else {
        if ('postalCodeUA' in address.branch) addr = address.branch.postalCodeUA + " ";
        addr += "Україна, ";
        if ('areaDescription' in address.city) addr += address.city.areaDescription + ", ";
        addr += address.city.description + ", " + address.branch.description;
      }
    }
    postAPICall('orders/hash', {
      userId: userId, token: token, total: total.total, promo: promo,
      lines: lines, clientName: WriteHelper.filterSimpleText(fullName), phone: WriteHelper.filterSimpleText(address.phone),
      address: WriteHelper.filterSimpleText(addr), comment: WriteHelper.filterSimpleText(comment)
    }, (data) => {
      createPayForm({ ...data, total: total.total }, address.phone);
    });
  }

  var physicalProducts = false;
  for (i = 0; i < cart.length; i++) {
    if (cart[i].digital === 0) {
      physicalProducts = true;
      break;
    }
  }

  return <div className='mainContainer' style={{ backgroundColor: "white" }}>
    <Menu />
    <div style={{ textAlign: "center" }}>
      <h1 className="top24">
        <img alt="" src="/icons/shop.png" style={{ width: "72px", verticalAlign: "text-bottom", marginRight: "8px" }} />
        <Translate>Мій кошик</Translate>
      </h1>
    </div>
    {cart.length === 0 ? <EmptyCart /> :
      <div className="cartItemDiv">
        <Table striped borderless hover>
          <thead>
            <tr className="cartTableHeader">
              <th colSpan="3" style={{ color: "inherit", backgroundColor: "inherit", textAlign: "center" }}>
                <Translate>Продукт</Translate></th>
              <th style={{ color: "inherit", backgroundColor: "inherit", textAlign: "center" }}>
                <Translate>Ціна</Translate></th>
            </tr>
          </thead>
          <tbody>
            {
              cart.map((item, i) => {
                return <CartItem key={i} index={i} item={item} updateSubtotal={updateSubtotal} />
              })
            }
          </tbody>
        </Table>
        <hr />
        <Row>
          <Col sm="6" style={{ padding: "12px" }}>
            <p><Translate>Виберіть акцію</Translate>:</p>
            <Dropdown>
              <Dropdown.Toggle variant="light" id="dropdown-basic"
                style={{ maxWidth: "400px", width: "300px", backgroundColor: "#f2f2f2" }}>
                {selectedPromo !== null ? getPromoDescription(selectedPromo) : ''}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {getPromosList().map((m, i) => {
                  return <Dropdown.Item key={i} onClick={() => onPromoChanged(m)}>{m.name}</Dropdown.Item>
                })}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col sm="6" style={{ padding: "8px" }}>
            <CartTotals subtotal={subtotal} promo={selectedPromo} />
          </Col>
        </Row>
        <Row>
          <Col style={{ marginTop: "24px", marginBottom: "36px", textAlign: "center" }}>
            {userId >= 0 ? <>
              {physicalProducts && <DeliveryAddress userName={userName} dicLang={dicLang} error={error}
                updateAddress={updateAddress} />}
              <Translate>Коментар</Translate> (<Translate>не обов'язково</Translate>)
              <Form.Control as="textarea" rows={2} value={comment} maxLength="128" onChange={(e) => setComment(e.target.value)} />
              <Button style={{ marginTop: "8px" }} onClick={purchase} size="lg" className="cartBuyButton">
                <Translate>Перейти до оформлення замовлення</Translate>
              </Button>
            </> : <>
              <p><Translate>Ви повинні створити обліковий запис, аби мати можливість робити покупки на платформі</Translate>.</p>
              <Button onClick={() => {
                document.getElementById("menuCreateAccountButton").click();
              }}><Translate>Створити акаунт</Translate></Button>
            </>
            }
          </Col>
        </Row>
      </div>
    }
    <Footer />
  </div>
}

export default Cart
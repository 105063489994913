import React, { useState, useEffect, useContext, useRef } from 'react';
import { AppContext } from '../../AppContext/AppContext';
import { getAPICall } from '../../Components/APICall';

const CitySearch = ({setCity}) => {
    var { userId, token } = useContext(AppContext);
    const [inputValue, setInputValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [showResults, setShowResults] = useState(false);
    const [userEditing, setUserEditing] = useState(false);
    const debounceTimeout = useRef(null);

    useEffect(() => {
        if (!userEditing || inputValue.trim() === '') {
            setSuggestions([]);
            setShowResults(false);
            return;
        }
        
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }

        debounceTimeout.current = setTimeout(() => {
            getAPICall('novaposhta/cities', { userId: userId, token: encodeURIComponent(token), city: encodeURIComponent(inputValue) }, 
                (response) => {
                    if (response && 'cities' in response) {
                        setSuggestions(response.cities);
                        setShowResults(true);
                    } else { 
                        setShowResults(false); 
                        setSuggestions([]); 
                    }
                }, 
                () => { setShowResults(false); setSuggestions([]); }
            );
        }, 500);
        
        return () => clearTimeout(debounceTimeout.current);
    }, [inputValue, userId, token, userEditing]); 

    return (
        <div className="form-block" style={{ position: 'relative', width: "74%" }}>
            <i className="search-clear" onClick={() => { 
                setInputValue('');
                setUserEditing(true); // Se activa la edición al borrar
                setCity(null);
            }} 
                style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '10px' }}>
                ✖
            </i>
            <input
                type="text"
                className="main-input search-input"
                autoComplete="off"
                value={inputValue}
                placeholder="Введіть місто"
                onChange={(e) => {
                    setInputValue(e.target.value);
                    setCity(null);
                    setUserEditing(true); // Se activa la edición cuando el usuario escribe
                }}
                onFocus={() => {
                    if (userEditing) setShowResults(true); // Solo muestra el desplegable si el usuario está editando
                }}
                onBlur={() => setTimeout(() => setShowResults(false), 200)}
                style={{ width: '100%', padding: '10px', border: '1px solid #ccc', borderRadius: '5px' }}
            />
            {showResults && suggestions.length > 0 && (
                <div className="search-result" style={{ position: 'absolute', width: '100%', background: 'white', border: '1px solid #ccc', borderRadius: '5px', marginTop: '5px', zIndex: '1000' }}>
                    <ul style={{ listStyle: 'none', margin: 0, padding: 0 }}>
                        {suggestions.map((city) => (
                            <li
                                key={city.ref}
                                onMouseDown={() => {
                                    setInputValue(city.description);
                                    setCity(city);
                                    setUserEditing(false);
                                    setTimeout(() => setShowResults(false), 100);
                                }}
                                style={{ padding: '4px 10px', cursor: 'pointer', borderBottom: '1px solid #eee' }}
                            >
                                {city.description}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default CitySearch;
